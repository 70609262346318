import React from 'react';
import { graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import { Col, Row } from 'components/Core/Grid';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import StyledRichText from 'components/RichText/StyledRichText';
import Text from 'components/Core/Text';
import ContentfulAccordion from 'components/Contentful/ContentfulAccordion';
import SocialSharing from '../components/SocialSharing';
import DownloadableContentFilters from '../components/Page/Downloads/SplashPage/Filter';
import 'components/Page/Downloads/SplashPage/Filter/downloadFilter.less';

const DownloadSplashTemplate = (props) => {
  const { industry, url: pathname } = props.pageContext;
  const pageComponents = props.data.contentfulComponentCollection.components;
  const hero = pageComponents.find((component) => component.__typename === 'ContentfulDynamicHero');
  const copy = pageComponents.find((component) => component.__typename === 'ContentfulRichText');
  const seo = pageComponents.find((component) => component.__typename === 'ContentfulSeo');
  const accordion = pageComponents.find(
    (component) => component.__typename === 'ContentfulAccordion',
  );
  const allRawPosts = props?.data?.posts?.nodes;
  const allPosts = allRawPosts;

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Licensing',
        icon: null,
        url: industry ? '/licensing' : '',
      },
      {
        ...(industry && {
          title: industry,
          icon: null,
          url: '',
        }),
      },
    ],
    absolute: true,
    transparent: true,
    theme: 'light',
  };

  const handleFilterUpdate = (value) => {
    navigate(`/licensing/${value.toLowerCase()}`);
  };

  return (
    <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={seo} layout="toolbox" {...props}>
      <Container>
        <Hero
          data={hero}
          noOverlap
          copy={
            <>
              <Text type="overline" color="White">
                {JSON.parse(hero.heading1.raw)?.content[0].content[0].value}
              </Text>
              <Text type="h1" size="h2" color="White">
                {JSON.parse(hero.subhead.raw)?.content[0].content[0].value}
              </Text>
            </>
          }
        />
        <div className="mobile-filter-container max-md">
          <DownloadableContentFilters
            posts={allPosts}
            filteredPosts={allPosts}
            type="Licensing"
            industry={industry}
            title="Get Trained in Your State"
            device="mobile"
          />
        </div>
        <div className="download-industry--content">
          <Row
            gutter={{
              xs: 40,
              sm: 40,
              lg: 80,
              xl: 80,
              xxl: 80,
            }}
            justify="space-between"
          >
            <Col sx={24} md={13}>
              <div className="download-industry--copy">
                <StyledRichText {...copy} />
                {accordion && (
                  <ContentfulAccordion
                    customHeading={<h5>{accordion.heading}</h5>}
                    {...accordion}
                  />
                )}
              </div>
            </Col>
            <Col sm={10}>
              <div className="download-industry--filters">
                <SocialSharing placement="blog" />
                <DownloadableContentFilters
                  posts={allPosts}
                  type="Licensing"
                  industry={industry}
                  title="Get Trained in Your State"
                  handleFilterUpdate={handleFilterUpdate}
                  pathname={pathname}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </ToolboxLayout>
  );
};

export default DownloadSplashTemplate;

export const DownloadIndustryTemplateQuery = graphql`
  query DownloadIndustryTemplateQuery($heroContentfulId: String!, $contentType: String!) {
    contentfulComponentCollection(contentful_id: { eq: $heroContentfulId }) {
      ...ComponentCollection
    }
    posts: allContentfulDownloadableContent(
      filter: { downloadableContentType: { eq: $contentType } }
    ) {
      nodes {
        ...DownloadableContent
      }
    }
  }
`;

const Container = styled.div`
  .mobile-filter-container {
    padding: 28px 28px 0 28px;

    .download-posts-container--title-container {
      display: flex;
      justify-content: space-between;

      img {
        margin-right: 10px;
      }
    }

    .download-posts-container--filter-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .section-faq--container {
    padding-left: 0;
    padding-right: 0;
  }

  .download-industry--content {
    display: flex;
    padding: 0px 28px;
    max-width: 1252px;
    margin: 0px auto 80px;
  }

  .download-industry--filters {
    padding-top: 60px;
    position: sticky;
    top: 75px;
    display: block !important;

    .downloadable-content-filter--container {
      margin-right: 80px;
    }

    button {
      width: 100%;
      margin-top: 0;
    }
  }

  .get-service-titan-demo--text-cta {
    width: 83%;
  }

  .download-industry--copy {
    padding-top: 20px;
  }

  @media (max-width: 575px) {
    .download-industry--filters {
      display: none !important;
    }
  }
`;
